<template>
    <client-page class="wrap--mypage">
        <sub-visual visual="마이페이지" subTitle="My page" />

        <v-container>
            <mypage-navigation-row />

            <div>
                <v-data-table v-bind="{ ...attrs_table, items, headers }" class="v-data-table--default v-data-table--none-border-left">
                    <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                    <template #[`item.content`]="{ item, value }">
                        <div class="text-truncate">
                            <span class="grey-2--text">[{{ item.type }}]</span> <b v-if="item?.mention" class="primary--text">{{ item.mention }}</b> <span>{{ value }}</span>
                        </div>
                    </template>
                    <template #[`item.to`]="{ value }">
                        <template v-if="!!value">
                            <v-btn :to="value" x-small color="grey-7">게시글로 이동</v-btn>
                        </template>
                        <template v-else>
                            <v-btn x-small outlined color="grey-7" style="pointer-events:none;">삭제된 게시글</v-btn>
                        </template>
                    </template>
                </v-data-table>

                <div class="v-pagination-wrap">
                    <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="my-4" @input="search()" />
                </div>

                <v-fade-transition>
                    <v-overlay v-show="loading" absolute color="white" light>
                        <v-progress-circular size="100" width="2" indeterminate color="primary" />
                    </v-overlay>
                </v-fade-transition>
            </div>
        </v-container>
    </client-page>
</template>

<script>
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import MypageNavigationRow from "@/components/client/mypage-navigation-row.vue";

import api from "@/api";
import { attrs_table } from "@/assets/variables";

const headers = [
    { width: "auto", align: "left", text: "댓글", value: "content", class: "text-center" },
    { align: "left", text: "등록일", value: "createdAt", formatter: (value) => value.toDate(), class: "w-18 w-lg-14" },
    { align: "center", text: "게시글", value: "to", class: "w-18 w-lg-14" },
];

export default {
    components: {
        SubVisual,
        ClientPage,
        MypageNavigationRow,
    },
    data() {
        return {
            loading: false,
            attrs_table,
            headers,

            page: +this.$route.query.page || 1,
            totalCount: 0,
            limit: 10,

            comments: [],
        };
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
        pageCount() {
            return Math.ceil(this.totalCount / this.limit) || 1;
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        items() {
            return (this.comments || []).map((item, index) => ({ ...item, index: +this.totalCount - (+this.page - 1) * +this.limit - index, to: item?.review ? `/reviews/${item.review._id}/comments/${item._id}` : item?.board ? `/community/boards/${item.board._id}/comments/${item._id}` : undefined }));
        },
        filter() {
            return {
                searchValue: this.$route.query.keyword,
            };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        "filter.searchValue"() {
            this.search(false);
        },
    },
    methods: {
        async init() {
            if (!this.accessToken) {
                this.$router.push({
                    path: "/login",
                    params: this.$router.params,
                });
            }
            this.search(false);
        },

        async search(routable = true) {
            this.loading = true;
            const { page, limit, skip } = this;
            if (routable) this.$router.push({ query: Object.assign({}, this.filter, { page }) });

            var {
                summary: { totalCount },
                comments,
            } = await api.v1.me.comments.gets({
                headers: { skip, limit },
                params: { ...this.filter },
            });

            this.comments = comments;
            this.totalCount = totalCount;

            this.loading = false;
        },

        alert(message) {
            alert(message);
        },
    },
};
</script>

<style scoped></style>

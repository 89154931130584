var render = function render(){
  var _vm$me, _vm$me2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap--mypage"
  }, [_c('sub-visual', {
    attrs: {
      "visual": "마이페이지",
      "subTitle": "My page"
    }
  }), _c('v-container', [_c('mypage-navigation-row'), _vm.me ? _c('v-tabs-items', {
    attrs: {
      "value": _vm.tab
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": "view"
    }
  }, [_c('div', {
    staticClass: "table-style table-style--default"
  }, [_c('myinfo-row', {
    attrs: {
      "label": "아이디"
    }
  }, [_vm._v(" " + _vm._s(_vm.me.username) + " ")]), _c('myinfo-row', {
    attrs: {
      "label": "닉네임"
    }
  }, [_vm._v(" " + _vm._s(_vm.me.nickname) + " ")]), _c('myinfo-row', {
    attrs: {
      "label": "이름"
    }
  }, [_vm._v(" " + _vm._s(_vm.me.name || "-") + " ")]), _vm.me.phone ? _c('myinfo-row', {
    attrs: {
      "label": "연락처"
    }
  }, [_vm._v(" " + _vm._s(_vm.me.phone1) + " - " + _vm._s(_vm.me.phone2) + " - " + _vm._s(_vm.me.phone3) + " ")]) : _c('myinfo-row', {
    attrs: {
      "label": "연락처"
    }
  }, [_vm._v(" - ")]), _c('myinfo-row', {
    attrs: {
      "label": "이벤트수신"
    }
  }, [(_vm$me = _vm.me) !== null && _vm$me !== void 0 && _vm$me.allowsAds ? [_vm._v(" 동의 ")] : _vm._e(), !((_vm$me2 = _vm.me) !== null && _vm$me2 !== void 0 && _vm$me2.allowsAds) ? [_vm._v(" 비동의 ")] : _vm._e()], 2)], 1), _c('div', {
    staticClass: "mt-20 mt-md-30"
  }, [_c('div', {
    staticClass: "v-btn--group justify-end"
  }, [_c('v-btn', {
    staticClass: "w-md-100 mw-md-100px",
    attrs: {
      "large": "",
      "color": "grey-7"
    },
    on: {
      "click": _vm.goModify
    }
  }, [_vm._v("수정")])], 1)])]), _c('v-tab-item', {
    attrs: {
      "value": "modify"
    }
  }, [_c('p', {
    staticClass: "font-size-14 grey-a--text text-right mb-8 mb-md-12"
  }, [_c('span', {
    staticStyle: {
      "color": "rgb(255, 0, 0)"
    }
  }, [_vm._v("*")]), _vm._v(" 필수입력입니다.")]), _c('div', {
    staticClass: "table-style table-style--default"
  }, [_c('myinfo-modify-row', {
    attrs: {
      "label": "아이디"
    }
  }, [_vm._v(_vm._s(_vm.me.username))]), _c('myinfo-modify-row', {
    attrs: {
      "label": "비밀번호",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    staticClass: "w-100 mw-lg-500px",
    attrs: {
      "type": "password",
      "name": "password",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.me.subPassword,
      callback: function ($$v) {
        _vm.$set(_vm.me, "subPassword", $$v);
      },
      expression: "me.subPassword"
    }
  }), _c('small', {
    staticClass: "text-grey text-grey--right"
  }, [_vm._v("(영문대소문자/숫자/특수문자 중 2가지 이상 조합, 8 - 16자)")])], 1), _c('myinfo-modify-row', {
    attrs: {
      "label": "비밀번호 확인",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    staticClass: "w-100 mw-lg-500px",
    attrs: {
      "type": "password",
      "name": "",
      "value": "",
      "outlined": "",
      "hide-details": "",
      "placeholder": "비밀번호를 재입력하세요."
    },
    model: {
      value: _vm.me.subPassword2,
      callback: function ($$v) {
        _vm.$set(_vm.me, "subPassword2", $$v);
      },
      expression: "me.subPassword2"
    }
  })], 1), _c('myinfo-modify-row', {
    attrs: {
      "label": "닉네임",
      "pointer": ""
    }
  }, [_c('v-row', {
    staticClass: "w-100 mw-lg-500px",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "mr-10"
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.me.nickname,
      callback: function ($$v) {
        _vm.$set(_vm.me, "nickname", $$v);
      },
      expression: "me.nickname"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--input",
    attrs: {
      "color": "primary",
      "height": "100%"
    },
    on: {
      "click": _vm.makeNickname
    }
  }, [_vm._v("자동생성")])], 1)], 1)], 1), _c('myinfo-modify-row', {
    attrs: {
      "label": "이름"
    }
  }, [_c('v-text-field', {
    staticClass: "w-100 mw-lg-500px",
    attrs: {
      "type": "text",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.me.name,
      callback: function ($$v) {
        _vm.$set(_vm.me, "name", $$v);
      },
      expression: "me.name"
    }
  })], 1), _c('myinfo-modify-row', {
    attrs: {
      "label": "연락처"
    }
  }, [_c('div', {
    staticClass: "mw-lg-500px"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-select', {
    attrs: {
      "items": ['010', '011', '016', '017', '019'],
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.me.phone1,
      callback: function ($$v) {
        _vm.$set(_vm.me, "phone1", $$v);
      },
      expression: "me.phone1"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_c('v-text-field', {
    attrs: {
      "maxlength": "4",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": function ($event) {
        _vm.me.phone2 = _vm.me.phone2.replace(/[^0-9]/g, '');
      }
    },
    model: {
      value: _vm.me.phone2,
      callback: function ($$v) {
        _vm.$set(_vm.me, "phone2", $$v);
      },
      expression: "me.phone2"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_c('v-text-field', {
    attrs: {
      "maxlength": "4",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": function ($event) {
        _vm.me.phone2 = _vm.me.phone2.replace(/[^0-9]/g, '');
      }
    },
    model: {
      value: _vm.me.phone3,
      callback: function ($$v) {
        _vm.$set(_vm.me, "phone3", $$v);
      },
      expression: "me.phone3"
    }
  })], 1)], 1)], 1)]), _c('myinfo-modify-row', {
    attrs: {
      "label": "이벤트수신"
    }
  }, [_c('v-switch', {
    attrs: {
      "inset": "",
      "hide-details": "",
      "label": _vm.me.allowsAds ? '동의' : '비동의'
    },
    model: {
      value: _vm.me.allowsAds,
      callback: function ($$v) {
        _vm.$set(_vm.me, "allowsAds", $$v);
      },
      expression: "me.allowsAds"
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt-40 mt-md-70"
  }, [_c('div', {
    staticClass: "v-btn--group justify-center"
  }, [_c('v-btn', {
    staticClass: "w-100 mw-180px",
    attrs: {
      "x-large": "",
      "rounded": "",
      "outlined": "",
      "color": "grey-d"
    },
    on: {
      "click": function ($event) {
        return _vm.goView();
      }
    }
  }, [_c('span', {
    staticClass: "grey-7--text"
  }, [_vm._v(_vm._s(_vm.$t("common.취소")))])]), _c('v-btn', {
    staticClass: "w-100 mw-180px",
    attrs: {
      "x-large": "",
      "rounded": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(_vm._s(_vm.$t("common.confirm")))])], 1)])])], 1) : _vm._e()], 1), _c('daum-postcode', {
    ref: "daumPostcode",
    on: {
      "change": function (_ref) {
        var postcode = _ref.postcode,
          address = _ref.address;
        return Object.assign(_vm.me, {
          postcode,
          address1: address
        });
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
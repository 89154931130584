<template>
    <client-page class="wrap--mypage">
        <!-- 서브비주얼 -->
        <sub-visual visual="마이페이지" subTitle="My page" />

        <v-container>
            <mypage-navigation-row />

            <v-tabs-items :value="tab" v-if="me">
                <v-tab-item value="view">
                    <div class="table-style table-style--default">
                        <myinfo-row label="아이디"> {{ me.username }} </myinfo-row>
                        <myinfo-row label="닉네임"> {{ me.nickname }} </myinfo-row>
                        <myinfo-row label="이름"> {{ me.name || "-" }} </myinfo-row>
                        <myinfo-row label="연락처" v-if="me.phone"> {{ me.phone1 }} - {{ me.phone2 }} - {{ me.phone3 }} </myinfo-row>
                        <myinfo-row label="연락처" v-else> - </myinfo-row>
                        <myinfo-row label="이벤트수신">
                            <template v-if="me?.allowsAds"> 동의 </template>
                            <template v-if="!me?.allowsAds"> 비동의 </template>
                        </myinfo-row>
                    </div>

                    <!-- 하단버튼 -->
                    <div class="mt-20 mt-md-30">
                        <div class="v-btn--group justify-end">
                            <v-btn @click="goModify" large color="grey-7" class="w-md-100 mw-md-100px">수정</v-btn>
                        </div>
                    </div>
                </v-tab-item>

                <v-tab-item value="modify">
                    <p class="font-size-14 grey-a--text text-right mb-8 mb-md-12"><span style="color: rgb(255, 0, 0);">*</span> 필수입력입니다.</p>

                    <div class="table-style table-style--default">
                        <myinfo-modify-row label="아이디">{{ me.username }}</myinfo-modify-row>
                        <myinfo-modify-row label="비밀번호" pointer>
                            <v-text-field v-model="me.subPassword" type="password" name="password" outlined hide-details class="w-100 mw-lg-500px"></v-text-field>
                            <small class="text-grey text-grey--right">(영문대소문자/숫자/특수문자 중 2가지 이상 조합, 8 - 16자)</small>
                        </myinfo-modify-row>
                        <myinfo-modify-row label="비밀번호 확인" pointer>
                            <v-text-field v-model="me.subPassword2" type="password" name="" value="" outlined hide-details class="w-100 mw-lg-500px" placeholder="비밀번호를 재입력하세요." />
                        </myinfo-modify-row>
                        <myinfo-modify-row label="닉네임" pointer>
                            <v-row no-gutters class="w-100 mw-lg-500px">
                                <v-col class="mr-10">
                                    <v-text-field v-model="me.nickname" type="text" outlined hide-details />
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn @click="makeNickname" color="primary" height="100%" class="v-btn--input">자동생성</v-btn>
                                </v-col>
                            </v-row>
                        </myinfo-modify-row>
                        <myinfo-modify-row label="이름">
                            <v-text-field v-model="me.name" type="text" outlined hide-details class="w-100 mw-lg-500px" />
                        </myinfo-modify-row>

                        <myinfo-modify-row label="연락처">
                            <div class="mw-lg-500px">
                                <v-row class="row--x-small" align="center">
                                    <v-col>
                                        <v-select v-model="me.phone1" :items="['010', '011', '016', '017', '019']" outlined hide-details></v-select>
                                    </v-col>
                                    <v-col cols="auto">-</v-col>
                                    <v-col>
                                        <v-text-field v-model="me.phone2" maxlength="4" outlined hide-details @input="me.phone2 = me.phone2.replace(/[^0-9]/g, '')" />
                                    </v-col>
                                    <v-col cols="auto">-</v-col>
                                    <v-col>
                                        <v-text-field v-model="me.phone3" maxlength="4" outlined hide-details @input="me.phone2 = me.phone2.replace(/[^0-9]/g, '')" />
                                    </v-col>
                                </v-row>
                            </div>
                        </myinfo-modify-row>
                        <myinfo-modify-row label="이벤트수신">
                            <v-switch v-model="me.allowsAds" inset hide-details :label="me.allowsAds ? '동의' : '비동의'" />
                        </myinfo-modify-row>
                    </div>

                    <!-- 하단버튼 -->
                    <div class="mt-40 mt-md-70">
                        <div class="v-btn--group justify-center">
                            <v-btn @click="goView()" x-large rounded outlined color="grey-d" class="w-100 mw-180px">
                                <span class="grey-7--text">{{ $t("common.취소") }}</span>
                            </v-btn>
                            <v-btn @click="save" x-large rounded color="primary" class="w-100 mw-180px">{{ $t("common.confirm") }}</v-btn>
                        </div>
                    </div>
                </v-tab-item>
            </v-tabs-items>
        </v-container>

        <daum-postcode ref="daumPostcode" @change="({ postcode, address }) => Object.assign(me, { postcode, address1: address })"></daum-postcode>
    </client-page>
</template>

<script>
import CryptoAES from "@/plugins/crypto-aes";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import MyinfoRow from "@/components/client/mypage/myinfo-row.vue";
import MyinfoModifyRow from "@/components/client/mypage/myinfo-modify-row.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";

import api from "@/api";
import MypageNavigationRow from "@/components/client/mypage-navigation-row.vue";
import { makeNickname } from "@/plugins/makeNickname";

makeNickname;
export default {
    components: {
        SubVisual,
        MyinfoRow,
        MyinfoModifyRow,
        ClientPage,
        DaumPostcode,
        MypageNavigationRow,
    },
    props: {
        tab: { type: String, default: "view" },
    },
    data() {
        return {
            me: null,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        makeNickname() {
            this.me.nickname = makeNickname();
        },

        async init() {
            try {
                if (!this.accessToken) {
                    this.$router.push({
                        path: "/login",
                        params: this.$router.params,
                    });
                    return;
                }

                var { user } = await api.v1.me.get();
                var [phone1, phone2, phone3] = (user.phone || "").phoneNumberFormat().split("-");

                this.me = {
                    ...user,
                    phone1,
                    phone2,
                    phone3,
                };
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        checkPassword(password) {
            var checkSpecial = /[~!@#$%^&*()_+|<>?:{}]/;
            var checkEng = /[a-zA-Z]/;
            var checkNum = /[0-9]/;

            if (!checkSpecial.test(password)) throw new Error("비밀번호에 특수문자가 포함되어있어야합니다.");
            if (!checkEng.test(password)) throw new Error("비밀번호에 영문이 포함되어있어야합니다.");
            if (!checkNum.test(password)) throw new Error("비밀번호에 숫자가 포함되어있어야합니다.");
            if (password.length < 8 || 16 < password.length) throw new Error("비밀번호는 8~16자까지입니다.");
        },

        validate() {
            try {
                if ((this.me.subPassword || this.me.subPassword2) && this.me.subPassword != this.me.subPassword2) throw new Error("비밀번호 및 비밀번호확인이 일치하지 않습니다");
                if (this.me.subPassword && this.me.subPassword2) this.checkPassword(this.me.subPassword);

                return true;
            } catch (error) {
                alert(error.message);
            }
            return false;
        },

        async save() {
            if (this.validate()) {
                await api.v1.me.put({
                    ...this.me,
                    subPassword: this.me.subPassword ? CryptoAES.encrypt(this.me.subPassword) : undefined,
                    subPassword2: undefined,
                });

                alert("수정되었습니다");

                this.init();
                this.goView();
            }
        },

        goTop() {
            window.scrollTo({ top: 400, behavior: "smooth" });
        },
        goModify() {
            this.goTop();
            this.$router.push("/mypage/myinfo/modify");
        },
        goView() {
            this.goTop();
            this.$router.push("/mypage/myinfo/view");
        },
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
        phone() {
            return this.phone1 && this.phone2 && this.phone3 ? [this.phone1, this.phone2, this.phone3].join("-") : undefined;
        },
        payload() {
            return this.$store.state.payload || {};
        },
        scope() {
            return this.payload.scope || [];
        },
    },
    watch: {
        "me.phone1"() {
            var { phone1, phone2, phone3 } = this.me;
            this.me.phone = [phone1, phone2, phone3].join("-");
        },
        "me.phone2"() {
            var { phone1, phone2, phone3 } = this.me;
            this.me.phone = [phone1, phone2, phone3].join("-");
        },
        "me.phone3"() {
            var { phone1, phone2, phone3 } = this.me;
            this.me.phone = [phone1, phone2, phone3].join("-");
        },
    },
};
</script>

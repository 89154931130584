var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap--mypage"
  }, [_c('sub-visual', {
    attrs: {
      "visual": "마이페이지",
      "subTitle": "My page"
    }
  }), _c('v-container', [_c('mypage-navigation-row'), _c('div', [_c('v-data-table', _vm._b({
    staticClass: "v-data-table--default v-data-table--none-border-left",
    attrs: {
      "item-class": function () {
        return 'cursor-pointer';
      }
    },
    on: {
      "click:row": _vm.go
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.content`,
      fn: function (_ref2) {
        var _item$_comments;
        var item = _ref2.item,
          value = _ref2.value;
        return [_c('div', {
          staticClass: "d-flex"
        }, [_c('div', {
          staticClass: "text-truncate"
        }, [_vm._v(_vm._s(value))]), _c('span', {
          staticClass: "primary--text pl-6"
        }, [_vm._v("(" + _vm._s((_item$_comments = item._comments) === null || _item$_comments === void 0 ? void 0 : _item$_comments.length) + ")")])])];
      }
    }, {
      key: `item.to`,
      fn: function (_ref3) {
        var value = _ref3.value;
        return [!!value ? [_c('v-btn', {
          attrs: {
            "to": value,
            "icon": "",
            "text": "",
            "small": ""
          }
        }, [_c('v-icon', [_vm._v("mdi-open-in-new")])], 1)] : [_c('v-btn', {
          attrs: {
            "icon": "",
            "text": "",
            "small": "",
            "color": "grey lighten-1"
          },
          on: {
            "click": function ($event) {
              return _vm.alert('삭제된 게시글입니다.');
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-open-in-new")])], 1)]];
      }
    }], null, true)
  }, 'v-data-table', Object.assign({}, _vm.attrs_table, {
    items: _vm.items,
    headers: _vm.headers
  }), false)), _c('div', {
    staticClass: "text-right mt-20 mt-md-30"
  }, [_c('v-btn', {
    staticClass: "w-md-100 mw-md-100px",
    attrs: {
      "large": "",
      "color": "primary",
      "to": "/community/boards/write"
    }
  }, [_vm._v("작성하기")])], 1), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    staticClass: "my-4",
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function ($event) {
        return _vm.search();
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('v-fade-transition', [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "absolute": "",
      "color": "white",
      "light": ""
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "size": "100",
      "width": "2",
      "indeterminate": "",
      "color": "primary"
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
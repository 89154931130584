var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-style__row"
  }, [_c('div', {
    staticClass: "table-style__th font-weight-bold"
  }, [_vm._v(_vm._s(_vm.label)), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.pointer,
      expression: "pointer"
    }],
    staticStyle: {
      "color": "rgb(255, 0, 0)"
    }
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "table-style__td grey-7--text"
  }, [_vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <navigation-row v-bind="{ items }" />
</template>

<script>
import NavigationRow from "../dumb/navigation-row.vue";
const items = [
    { to: "/mypage/comments", text: "댓글 관리" },
    { to: "/mypage/reviews", text: "리뷰 관리" },
    { to: "/mypage/boards", text: "게시글 관리" },
    { to: "/mypage/question", text: "1:1 문의" },
    { to: "/mypage/myinfo", text: "회원정보 수정" },
];
export default {
    components: { NavigationRow },
    data: () => ({
        items,
    }),
};
</script>

<style></style>

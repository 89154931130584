<template>
    <client-page class="wrap--mypage">
        <sub-visual visual="마이페이지" subTitle="My page" />

        <v-container>
            <mypage-navigation-row />

            <div>
                <v-data-table width="100%" v-bind="{ ...attrs_table, items, headers }" class="v-data-table--default v-data-table--none-border-left" :item-class="() => 'cursor-pointer'" @click:row="go">
                    <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                    <template #[`item.location.name`]="{ value }">
                        <div class="grey-2--text text-truncate">{{ value }}</div>
                    </template>
                    <template #[`item.subject`]="{ value }">
                        <div class="text-truncate">{{ value }}</div>
                    </template>
                    <template #[`item.stars.total`]="{value}">
                        <v-rating :value="value" readonly half-increments dense color="warning" background-color="warning" class="justify-center" />
                    </template>
                </v-data-table>

                <div class="text-right mt-20 mt-md-30">
                    <v-btn large color="primary" to="/reviews/write" class="w-md-100 mw-md-100px">작성하기</v-btn>
                </div>

                <div class="v-pagination-wrap">
                    <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="my-4" @input="search()" />
                </div>

                <v-fade-transition>
                    <v-overlay v-show="loading" absolute color="white" light>
                        <v-progress-circular size="100" width="2" indeterminate color="primary" />
                    </v-overlay>
                </v-fade-transition>
            </div>
        </v-container>
    </client-page>
</template>

<script>
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import MypageNavigationRow from "@/components/client/mypage-navigation-row.vue";

import api from "@/api";
import { attrs_table } from "@/assets/variables";

const headers = [
    { width: "20%", align: "center", text: "병원명", value: "locationName" },
    { width: "auto", align: "left", text: "제목", value: "subject", class: "text-center" },
    { align: "center", text: "평점", value: "stars.total", class: "w-26 w-md-24 w-lg-16" },
    { align: "center", text: "등록일", value: "createdAt", formatter: (value) => value.toDate(), class: "w-16 w-md-18 w-lg-16" },
];

export default {
    components: {
        SubVisual,
        ClientPage,
        MypageNavigationRow,
    },
    data() {
        return {
            loading: false,
            attrs_table,
            headers,

            page: +this.$route.query.page || 1,
            totalCount: 0,
            limit: 10,

            reviews: [],
        };
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
        pageCount() {
            return Math.ceil(this.totalCount / this.limit) || 1;
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        items() {
            return (this.reviews || []).map((item, index) => ({ ...item, index: +this.totalCount - (+this.page - 1) * +this.limit - index }));
        },
        filter() {
            return {
                searchValue: this.$route.query.keyword,
            };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        "filter.searchValue"() {
            this.search(false);
        },
    },
    methods: {
        async init() {
            if (!this.accessToken) {
                this.$router.push({
                    path: "/login",
                    params: this.$router.params,
                });
            }
            this.search(false);
        },

        async search(routable = true) {
            this.loading = true;
            const { page, limit, skip } = this;
            if (routable) this.$router.push({ query: Object.assign({}, this.filter, { page }) });

            var {
                summary: { totalCount },
                reviews,
            } = await api.v1.me.reviews.gets({
                headers: { skip, limit },
                params: { ...this.filter },
            });

            this.reviews = reviews;
            this.totalCount = totalCount;

            this.loading = false;
        },

        go({ _id }) {
            this.$router.push(`/reviews/${_id}`);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .cursor-pointer {
    cursor: pointer;
}
</style>

<template>
    <div class="table-style__row">
        <div class="table-style__th font-weight-bold">{{ label }}<span v-show="pointer" style="color: rgb(255, 0, 0);">*</span></div>
        <div class="table-style__td grey-7--text">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: String,
        pointer: Boolean,
    },
};
</script>

<style scoped>
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .table-style__th, .table-style__td{
        height: 58px;
    }
}
@media (min-width:1200px){
}
</style>